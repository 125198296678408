.loading-page {
  --loader-height: 3rem;
  --loader-width: 14rem;
  z-index: 10;
  background-color: #2666cf;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 5rem;
  transition: visibility .5s, opacity .5s;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loading-page--hide {
  opacity: 0;
  visibility: hidden;
}

.loading-page__loader {
  height: var(--loader-height);
  width: var(--loader-width);
  border-radius: 2000px;
  position: relative;
  overflow: hidden;
}

.loading-page__loader + .loading-page__loader {
  margin-top: .6rem;
}

.loading-page__loader:after {
  content: "";
  width: calc(var(--loader-width)  - 4rem);
  height: var(--loader-height);
  border-radius: inherit;
  background: #fff;
  animation: 4s ease-in-out infinite backwards loading-slide;
  position: absolute;
  right: 0;
  box-shadow: inset 0 -.6rem #dc64a033;
}

.loading-page__loader:nth-child(2):after {
  animation-delay: .2s;
}

.loading-page__loader:nth-child(3):after {
  animation-delay: .4s;
}

@keyframes loading-slide {
  0% {
    transform: translateX(calc(100% - 3rem));
  }

  50% {
    transform: translateX(calc(-1 * var(--loader-width)  + 3rem));
  }

  100% {
    transform: translateX(calc(100% - 3rem));
  }
}

/*# sourceMappingURL=index.dc063132.css.map */
