.loading-page {
    --loader-height: 3rem;
    --loader-width: 14rem;
    position: fixed;
    z-index: 10;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #2666CF;
    font-size: 5rem;
    transition: visibility 0.5s, opacity 0.5s;
}

.loading-page--hide {
    opacity: 0;
    visibility: hidden;
}

.loading-page__loader {
    height: var(--loader-height);
    width: var(--loader-width);
    border-radius: 2000px;
    position: relative;
    overflow: hidden;
}

.loading-page__loader + .loading-page__loader {
    margin-top: 0.6rem;
}

.loading-page__loader::after {
    content: '';
    position: absolute;
    right: 0;
    width: calc(var(--loader-width) - 4rem);
    height: var(--loader-height);
    border-radius: inherit;
    background: #ffffff;
    box-shadow: 0 -0.6rem 0 rgba(220, 100, 160, 0.2) inset;
    animation: loading-slide 4s ease-in-out backwards infinite;
}

.loading-page__loader:nth-child(2)::after {
    animation-delay: 0.2s;
}

.loading-page__loader:nth-child(3)::after {
    animation-delay: 0.4s;
}

@keyframes loading-slide {
    0% {
        transform: translateX(calc(100% - 3rem));
    }


    50% {
        transform: translateX(calc(-1 * var(--loader-width) + 3rem));
    }


    100% {
        transform: translateX(calc(100% - 3rem));
    }
}